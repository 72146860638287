import React from "react";
import Notices from "../components/Notices";

const NoticesPage = () => {
  return (
    <div style={{ padding: "2rem" }}>
      <Notices />
    </div>
    );
};

export default NoticesPage;
