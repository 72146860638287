import React from "react";
import './Loading.css'; // 위의 CSS 파일을 가져옵니다.

const Loading = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
      <p className="loader-text">로딩 중...</p>
    </div>
  );
};

export default Loading;
