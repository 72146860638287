import React, { useState } from "react";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header
      style={{
        backgroundColor: "#282c34",
        padding: "1rem",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        style={{
          color: "#61dafb",
          fontSize: "1.8rem",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "1rem",
        }}
      >
        Junhong123a’s Webpage
      </div>

      {/* 모바일 메뉴 버튼 */}
      <button
        onClick={toggleMobileMenu}
        style={{
          backgroundColor: "#61dafb",
          color: "#282c34",
          padding: "0.5rem 1rem",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          display: "none", // 기본적으로 PC에서는 숨김
          fontSize: "1rem",
        }}
        className="mobile-menu-button"
      >
        Menu
      </button>

      {/* 네비게이션 메뉴 */}
      <nav
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "1rem",
        }}
        className="nav-links"
      >
        <a
          href="/"
          style={{
            color: "#61dafb",
            margin: "0 1rem",
            textDecoration: "none",
            padding: "0.5rem 1rem",
            borderRadius: "8px",
            transition: "background-color 0.3s, color 0.3s",
          }}
        >
          Home
        </a>
        <a
          href="/info"
          style={{
            color: "#61dafb",
            margin: "0 1rem",
            textDecoration: "none",
            padding: "0.5rem 1rem",
            borderRadius: "8px",
            transition: "background-color 0.3s, color 0.3s",
          }}
        >
          Info
        </a>
        <a
          href="/specs"
          style={{
            color: "#61dafb",
            margin: "0 1rem",
            textDecoration: "none",
            padding: "0.5rem 1rem",
            borderRadius: "8px",
            transition: "background-color 0.3s, color 0.3s",
          }}
        >
          Specs
        </a>
        <a
          href="/pc-specs"
          style={{
            color: "#61dafb",
            margin: "0 1rem",
            textDecoration: "none",
            padding: "0.5rem 1rem",
            borderRadius: "8px",
            transition: "background-color 0.3s, color 0.3s",
          }}
        >
          PC Specs
        </a>
        <a
          href="/notices"
          style={{
            color: "#61dafb",
            margin: "0 1rem",
            textDecoration: "none",
            padding: "0.5rem 1rem",
            borderRadius: "8px",
            transition: "background-color 0.3s, color 0.3s",
          }}
        >
          Notices
        </a>
        <a
          href="https://junhong123a.notion.site"
          style={{
            color: "#61dafb",
            margin: "0 1rem",
            textDecoration: "none",
            padding: "0.5rem 1rem",
            borderRadius: "8px",
            transition: "background-color 0.3s, color 0.3s",
          }}
        >
          이전 사이트트
        </a>
      </nav>

      {/* 모바일 하단 메뉴 */}
      {isMobileMenuOpen && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            backgroundColor: "#282c34",
            position: "fixed",
            bottom: "0",
            left: "0",
            width: "100%",
            padding: "0.5rem 0",
            boxShadow: "0 -4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <a
            href="/"
            style={{
              color: "#61dafb",
              textDecoration: "none",
              padding: "0.5rem",
              textAlign: "center",
              width: "25%",
            }}
          >
            Home
          </a>
          <a
            href="/info"
            style={{
              color: "#61dafb",
              textDecoration: "none",
              padding: "0.5rem",
              textAlign: "center",
              width: "25%",
            }}
          >
            Info
          </a>
          <a
            href="/specs"
            style={{
              color: "#61dafb",
              textDecoration: "none",
              padding: "0.5rem",
              textAlign: "center",
              width: "25%",
            }}
          >
            Specs
          </a>
          <a
            href="/notices"
            style={{
              color: "#61dafb",
              textDecoration: "none",
              padding: "0.5rem",
              textAlign: "center",
              width: "25%",
            }}
          >
            Notices
          </a>
        </div>
      )}

      {/* 스타일 */}
      <style>
        {`
          /* PC에서는 메뉴 버튼 숨기기 */
          @media (min-width: 768px) {
            .mobile-menu-button {
              display: none;
            }

            .nav-links {
              display: flex;
            }
          }

          /* 모바일에서는 메뉴 항목을 세로로 쌓기 */
          @media (max-width: 767px) {
            .nav-links {
              display: none;
            }
          }
        `}
      </style>
    </header>
  );
};

export default Header;
