import React from "react";
const Info = () => {
  return (
      <div style={{ padding: "2rem" }}>
      <h1>Project Information</h1>
      <p>This project demonstrates integration with Notion's API using a React frontend and Express backend.</p>
    </div>
  );
};

export default Info;
