import React from "react";

const Specs = () => {
  return (
    <div style={{ padding: "2rem" }}>
      <h1>Specs</h1>
      <ul>
        <li><strong>Backend:</strong> Node.js, Express</li>
        <li><strong>Frontend:</strong> React</li>
        <li><strong>Database:</strong> Notion API</li>
      </ul>
    </div>
  );
};

export default Specs;
