import React from "react";
const PcSpecs = () => {
  return (
    <div style={{ padding: "2rem" }}>
      <h1>PC Specifications</h1>
      <ul>
        <li><strong>CPU:</strong> AMD Ryzen 5 PRO 4650G</li>
        <li><strong>GPU:</strong> AMD Radeon RX 580</li>
        <li><strong>RAM:</strong> 32GB DDR4</li>
        <li><strong>Storage:</strong> 1TB SSD</li>
      </ul>
    </div>
  );
};

export default PcSpecs;
