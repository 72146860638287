import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "./Loading"; // 로딩 컴포넌트

const Notices = () => {
  const [notices, setNotices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNotices = async () => {
      try {
        const response = await axios.get("https://api.junhong123a.kr/api/notices");
        setNotices(response.data);
      } catch (error) {
        console.error("Error fetching notices:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotices();
  }, []);

  // 가장 최근 추가된 공지 찾기 (가장 마지막에 추가된 것)
  const recentNotice = notices[0];

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ padding: "3rem" }}>
      <h1>Notices</h1>
      <div
        style={{
          display: "grid",
          gap: "1.5rem",
          gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
        }}
      >
        {notices.map((notice) => (
          <div
            key={notice.id}
            style={{
              backgroundColor: "#fff",
              padding: "1.5rem",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              position: "relative", // 카드 내에서 절대 위치 지정 가능
              border: notice.id === recentNotice.id ? "3px solid #3498db" : "none", // 최근 공지 강조
              minHeight: "150px", // 최소 높이 설정
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 style={{ margin: 0, fontSize: "1.25rem" }}>
                {notice.name}
                {notice.status && (
                  <span
                    style={{
                      backgroundColor:
                        notice.status === "공지"
                          ? "#2ecc71"
                          : notice.status === "긴급공지"
                          ? "#e74c3c"
                          : notice.status === "준비 중"
                          ? "#f39c12"
                          : notice.status === "공사 중"
                          ? "#ffc430"
                          : "#95a5a6", // 기본 색상
                      color: "white",
                      padding: "0.25rem 0.75rem",
                      borderRadius: "12px",
                      fontSize: "0.875rem",
                      textTransform: "uppercase",
                      marginLeft: "1rem", // 제목과 뱃지 사이 간격
                    }}
                  >
                    {notice.status}
                  </span>
                )}
              </h2>
            </div>

            <div
              style={{
                fontSize: "1rem",
                color: "#555",
              }}
            >
              <p>{notice.content}</p>
            </div>

            <div
              style={{
                textAlign: "right",
                fontSize: "0.875rem",
                color: "#777",
              }}
            >
              <p>{notice.date}</p>
            </div>

            {/* New 뱃지 오른쪽 위에 추가 */}
            {notice.id === recentNotice.id && (
              <div
                style={{
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                  backgroundColor: "#3498db",
                  color: "white",
                  padding: "0.5rem",
                  borderRadius: "12px",
                  fontSize: "0.875rem",
                  textTransform: "uppercase",
                }}
              >
                New
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notices;
