import React from "react";

const Footer = () => {
  return (
    <footer style={{
      background: "#282c34", 
      color: "#fff", 
      textAlign: "center", 
      padding: "1rem",
      position: "fixed",
      bottom: 0,
      width: "100%"
    }}>
      <p>© 2024 Seong Junhong Website Project</p>
    </footer>
  );
};

export default Footer;
