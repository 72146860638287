import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Info from "./pages/Info";
import Specs from "./pages/Specs";
import PcSpecs from "./pages/PcSpecs";
import NoticesPage from "./pages/NoticesPage";

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/info" element={<Info />} />
        <Route path="/specs" element={<Specs />} />
        <Route path="/pc-specs" element={<PcSpecs />} />
        <Route path="/notices" element={<NoticesPage />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
