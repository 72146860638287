import React from "react";

const Home = () => {
  return (
      <div style={{ padding: "2rem" }}>

          <h1>Welcome to the Notion Integration</h1>
          <p>This is the homepage of the project where you can explore various sections like notices, specs, and PC info.</p>
      </div>
  );
};

export default Home;
